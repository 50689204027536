import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TabChangeEvent } from '@epsilon/core-ui';
import { find, get } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import {
  AUDIENCE_TXT,
  BUILDER_TXT,
  DEFINITION_TXT,
  MONITOR_TXT,
  isDefined,
  ACTIVITY_TXT,
  AUDIENCE_AI_SELECTOR_TXT
} from '../utils/utils';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TableFilterService } from './overview-table/table-filter.service';
import { TableType } from './audience-overview.models';
import { SetActiveDataUniverse } from '../data-universe/data-universe.actions';
import { DataUniverse } from '../data-universe/data-universe.models';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import {
  selectActiveDataUniverse,
  selectDataUniverses,
} from '../data-universe/data-universe.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { selectContext } from '../context/context.reducer';
import { FEATURE_AUDIENCES} from '../utils/feature-utils';
import * as audienceBuilderActions from '../audience-builder/audience-builder.actions';
import { CountsService } from '../counts/counts.service';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { FeatureService } from '../utils/feature-service';
import { LoadingService } from '../services/loading.service';
import { UtilsService } from '../utils/utilservice';
import { FeatureAccessService } from '../feature-access/feature.access.service';
import { Action } from '../feature-access/feature.access.action';
import { CONTEXT_AUDIENCE_DEFINITION_AUDIENCE_AI } from '../utils/cab-context-feature-utils';

@UntilDestroy()
@Component({
  selector: 'lib-audience-overview',
  templateUrl: './audience-overview.component.html',
  styleUrls: ['./audience-overview.component.sass'],
  providers: [TableFilterService],
})
export class AudienceOverviewComponent implements OnInit{

  @ViewChild('successNotification', { static: true })
  public successNotification;
  @ViewChild('errorNotification', { static: true }) public errorNotification;
  errorNotificationMessage: string;
  contextId: string;
  productType: string;
  dataUniverses: DataUniverse[];
  activeDataUniverse: DataUniverse;
  listsEnabled: boolean;
  isTabsDataLoading= false;
  definitionWriteAction = Action.AUDIENCE_DEFINITION_WRITE;
  isAudienceAI: boolean;
  routeName: string;

  activeDataUniverse$ = this.store.select(selectActiveDataUniverse);
  disableGoToBuilder$: Observable<boolean> = this.activeDataUniverse$.pipe(
    filter(isDefined),
    map(
      (activeDataUniverse) =>
        !activeDataUniverse.id || activeDataUniverse.id === 'all'
    )
  );

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    public tableFilterService: TableFilterService,
    private location: Location,
    private countService: CountsService,
    private builderService: AudienceBuilderService,
    public featureService: FeatureService,
    protected loadingService: LoadingService,
    private utilsService: UtilsService,
    public featureAccessService: FeatureAccessService
  ) {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
        this.contextId = context?.id;
        if(this.productType) {
          this.listsEnabled = this.featureService.isFeatureEnabled(FEATURE_AUDIENCES);
        }
      });
  }

  ngOnInit() {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.routeName = this.componentName();
    if (this.routeName === DEFINITION_TXT) {
      this.refreshTab(DEFINITION_TXT);
    } else if(this.routeName === AUDIENCE_TXT) {
      this.refreshTab(AUDIENCE_TXT);
    } else if(this.routeName === MONITOR_TXT) {
      this.refreshTab(MONITOR_TXT);
    } else if(this.routeName === ACTIVITY_TXT) {
      this.refreshTab(ACTIVITY_TXT);
    } else {
      this.refreshTab(AUDIENCE_TXT);
    }
    observableCombineLatest([
      this.store.select(selectDataUniverses),
      this.activeDataUniverse$.pipe(filter(isDefined)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dataUniverses, activeDataUniverse]) => {
        this.dataUniverses = dataUniverses;
        // note: this is necessary to set the value of the control for coreui-select using the reference to same object
        this.activeDataUniverse = find(dataUniverses, {
          id: activeDataUniverse.id,
        });
        if(this.productType === 'DCDP' && this.dataUniverses.length > 0) {
          this.onDataUniverseSelect(this.dataUniverses[1]);
        }
        const name = this.route.snapshot.routeConfig.path;
        if(this.productType === 'DCDP' && (['deliveries'].includes(name))){
          this.tableFilterService.tableType$.next(ACTIVITY_TXT);
        }
      });

      this.loadingService.isLoading$.pipe(untilDestroyed(this)).subscribe((isLoading: any) => {
        this.isTabsDataLoading = isLoading;
      });
      this.errorNotificaionState()

      this.store.select(selectContext).pipe(untilDestroyed(this))
        .subscribe((context) => {
          this.isAudienceAI =  context?.metadata[CONTEXT_AUDIENCE_DEFINITION_AUDIENCE_AI];
        });

    }

  errorNotificaionState() {
    const navigationStateMessage = this.router.getCurrentNavigation()?.extras?.state?.["errorMessage"]
    if(navigationStateMessage){
      this.errorNotificationMessage = navigationStateMessage;
      this.errorNotification.show()
    }
  }

  tabChange(event: TabChangeEvent) {
    const label = get(event, ['tab', 'label']);
    let type: TableType = DEFINITION_TXT;
    if (label === 'Audience List' || label === 'Lists') {
      type = AUDIENCE_TXT;
    }
    if (label === 'Monitor' && this.productType !== 'DCDP') {
      type = MONITOR_TXT;
    }
    if (label === 'Deliveries' && this.productType === 'DCDP') {
      type = ACTIVITY_TXT;
    }
    this.location.go(
      `${this.utilsService.getProductBaseUrl(this.router, this.route)}/${
        this.contextId
      }/${type}`
    );
    this.refreshTab(type);
  }

  refreshTab(type: TableType) {
    this.tableFilterService.tableType$.next(type);
    if (this.tableFilterService.checkedAllStatus) {
      this.tableFilterService.checkedAllStatus = false;
      this.tableFilterService.rowSelected = 0;
    }
  }

  goToBuilder() {
    this.store.dispatch(new audienceBuilderActions.LoadPrebuiltAudience(null));
    this.countService.stopCron();
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      this.activeDataUniverse?.id,
      BUILDER_TXT,
    ]);
  }

  public goToAudienceAISelector(): void {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      AUDIENCE_AI_SELECTOR_TXT
    ]);
  }

  componentName(): string {
    const url = new URL(this.router.routerState.snapshot.url, window.location.origin);
    const currentUrl = url.pathname;
    const name = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

    if (!['definition', 'audience', 'monitor', 'deliveries'].includes(name)) {
      console.log('Invalid url');
      // TODO: Show/take to error page as value is different from definition or audience // NOSONAR
      this.router.navigate(['contexts']);
    }
    return name;
  }

  public onDataUniverseSelect(dataUniverse: DataUniverse) {
    if (dataUniverse && dataUniverse?.id !== this.activeDataUniverse?.id) {
      this.store.dispatch(new SetActiveDataUniverse(dataUniverse));
    }
  }
}
