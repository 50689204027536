export class EnvironmentConfig {
  environment = '';
}

export interface EnvironmentConfigData {
  production: boolean;
  envName: string;
  domainName: string;
  baseApiUrl: string;
  baseUiUrl: string;
  productBaseUrl: string;
  apiUrl?: string;
  cabApiUrl: {
    discovery: string;
    cab: string;
  };
  dataHubApiUrl?: string;
  foundationApiUrl?: string;
  useMockData: boolean;
  features: {
    [key: string]: {
      aggregations?: boolean;
      scheduling?: boolean;
      audiences?: boolean;
      nestedAudiences?: boolean;
      nestedDefinitions?: boolean;
      notCondition?: boolean;
      relativeDates?: boolean;
      exportCampaignExtracts?: boolean;
      showUpdateMetadata?: boolean;
      attributeSearch?: boolean;
      audienceBuilderEnumSelection?: boolean;
      audienceBuilderWizardFlow?: boolean;
      ownedChannelMapping?: boolean;
      showPCBCNavTree?: boolean;
      cabAttributeActions?: boolean;
      paidConnectionsInfo?: boolean;
      audienceQueryCaseInsensitive?: boolean;
      activationSchedules?: boolean;
      rolePermissionService?: boolean,
      assertListing?: boolean;
      savedSearch?: boolean;
      tagsFilter?: boolean;
    };
  };
}

export enum Environment {
  dev = 'dev',
  qa = 'qa',
  stage = 'stage',
  prod = 'prod',
}

export const ENV_CONFIG_MAP = new Map<string, EnvironmentConfigData>([
  [
    'local',
    {
      production: false,
      envName: 'dev',
      domainName: 'local',
      baseApiUrl: 'http://localhost:8069',
      baseUiUrl: 'http://localhost:4200',
      productBaseUrl: 'audiences',
      apiUrl: 'http://localhost:8069/cab/proxy/ams',
      cabApiUrl: {
        discovery: 'https://us-integration-api.publicispeoplecloud.com',
        cab: 'http://localhost:8069',
      },
      dataHubApiUrl: 'https://acpapi.datahub.epsilon.com',
      foundationApiUrl: 'https://dev1.api.a-us1.peoplecloud.epsilon.com',
      useMockData: false,
      // these features should come from backend which needs to be configurable
      features: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DEFAULT: {
          aggregations: true,
          scheduling: true,
          nestedAudiences: true,
          nestedDefinitions: true,
          audiences: true,
          notCondition: true,
          relativeDates: true,
          exportCampaignExtracts: true,
          showUpdateMetadata: true,
          showPCBCNavTree: false,
          attributeSearch: true,
          audienceBuilderEnumSelection: true,
          audienceBuilderWizardFlow: false,
          ownedChannelMapping: false,
          cabAttributeActions: true,
          paidConnectionsInfo: false,
          audienceQueryCaseInsensitive: true,
          activationSchedules: false,
          rolePermissionService: true,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_CUSTOMER: {
          showPCBCNavTree: false
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPCL: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DCDP: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          exportCampaignExtracts: false,
          scheduling: false,
          paidConnectionsInfo: true,
          activationSchedules: true,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_PROSPECT: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false,
          audiences: false
        }
      }
    }
  ],
  [
    'dev',
    {
      production: true,
      envName: 'prod',
      domainName: 'dev',
      baseApiUrl: 'https://api-dev.audience.peoplecloud.epsilon.com',
      baseUiUrl: 'https://dev.audience.peoplecloud.epsilon.com',
      productBaseUrl: 'audiences',
      apiUrl: 'https://api-dev.audience.peoplecloud.epsilon.com/cab/proxy/ams',
      cabApiUrl: {
        discovery: 'https://us-integration-api.publicispeoplecloud.com',
        cab: 'https://api-dev.audience.peoplecloud.epsilon.com',
      },
      dataHubApiUrl: 'https://acpapi.datahub.epsilon.com',
      foundationApiUrl: 'https://dev1.api.a-us1.peoplecloud.epsilon.com',
      useMockData: false,
      // these features should come from backend which needs to be configurable
      features: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DEFAULT: {
          aggregations: true,
          scheduling: true,
          nestedAudiences: true,
          nestedDefinitions: true,
          audiences: true,
          notCondition: true,
          relativeDates: true,
          exportCampaignExtracts: true,
          showUpdateMetadata: false,
          showPCBCNavTree: false,
          attributeSearch: true,
          audienceBuilderEnumSelection: true,
          audienceBuilderWizardFlow: false,
          ownedChannelMapping: false,
          cabAttributeActions: true,
          paidConnectionsInfo: false,
          audienceQueryCaseInsensitive: true,
          activationSchedules: false,
          rolePermissionService: true,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_CUSTOMER: {
          showPCBCNavTree: false
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPCL: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DCDP: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          exportCampaignExtracts: false,
          scheduling: false,
          paidConnectionsInfo: true,
          activationSchedules: true,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_PROSPECT: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false,
          audiences: false
        }
      }
    }
  ],
  [
    'qa',
    {
      production: true,
      envName: 'prod',
      domainName: 'qa',
      baseApiUrl: 'https://api-qa.audience.peoplecloud.epsilon.com',
      baseUiUrl: 'https://qa.audience.peoplecloud.epsilon.com',
      productBaseUrl: 'audiences',
      apiUrl: 'https://api-qa.audience.peoplecloud.epsilon.com/cab/proxy/ams',
      cabApiUrl: {
        discovery: 'https://us-integration-api.publicispeoplecloud.com',
        cab: 'https://api-qa.audience.peoplecloud.epsilon.com',
      },
      dataHubApiUrl: 'https://u-acpapi.datahub.epsilon.com',
      foundationApiUrl: 'https://qa1.api.a-us1.peoplecloud.epsilon.com',
      useMockData: false,
      // these features should come from backend which needs to be configurable
      features: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DEFAULT: {
          aggregations: true,
          scheduling: true,
          nestedAudiences: true,
          nestedDefinitions: true,
          audiences: true,
          notCondition: true,
          relativeDates: true,
          exportCampaignExtracts: true,
          showUpdateMetadata: false,
          showPCBCNavTree: false,
          attributeSearch: true,
          audienceBuilderEnumSelection: true,
          audienceBuilderWizardFlow: false,
          ownedChannelMapping: false,
          cabAttributeActions: true,
          paidConnectionsInfo: false,
          audienceQueryCaseInsensitive: true,
          activationSchedules: false,
          assertListing: true,
          rolePermissionService: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_CUSTOMER: {
          audienceBuilderWizardFlow: true,
          showPCBCNavTree: false
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPCL: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DCDP: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          exportCampaignExtracts: false,
          scheduling: false,
          paidConnectionsInfo: true,
          showPCBCNavTree: false,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_PROSPECT: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false,
          audiences: false
        }
      }
    }
  ],
  [
    'stage',
    {
      production: true,
      envName: 'prod',
      domainName: 'stage',
      baseApiUrl: 'https://api-uat.audience.peoplecloud.epsilon.com',
      baseUiUrl: 'https://uat.audience.peoplecloud.epsilon.com',
      productBaseUrl: 'audiences',
      apiUrl: 'https://api-uat.audience.peoplecloud.epsilon.com/cab/proxy/ams',
      cabApiUrl: {
        discovery: 'https://us-integration-api.publicispeoplecloud.com',
        cab: 'https://api-uat.audience.peoplecloud.epsilon.com',
      },
      dataHubApiUrl: 'https://u-stgapi.datahub.epsilon.com',
      foundationApiUrl: 'https://stg1.api.a-us1.peoplecloud.epsilon.com',
      useMockData: false,
      // these features should come from backend which needs to be configurable
      features: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DEFAULT: {
          aggregations: true,
          scheduling: false,
          nestedAudiences: true,
          nestedDefinitions: false,
          audiences: true,
          notCondition: true,
          relativeDates: true,
          exportCampaignExtracts: true,
          showUpdateMetadata: false,
          showPCBCNavTree: false,
          attributeSearch: true,
          audienceBuilderEnumSelection: true,
          audienceBuilderWizardFlow: false,
          ownedChannelMapping: false,
          cabAttributeActions: true,
          paidConnectionsInfo: false,
          audienceQueryCaseInsensitive: true,
          activationSchedules: false,
          rolePermissionService: false,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_CUSTOMER: {
          audienceBuilderWizardFlow: true,
          showPCBCNavTree: false,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPCL: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false,
          showPCBCNavTree: true
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DCDP: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          scheduling: false,
          exportCampaignExtracts: false,
          showPCBCNavTree: false,
          paidConnectionsInfo: true,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_PROSPECT: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          exportCampaignExtracts: false,
          scheduling: false,
          showPCBCNavTree: true,
          audiences: false
        }
      }
    }
  ],
  [
    'prod',
    {
      production: true,
      envName: 'prod',
      domainName: 'prod',
      baseApiUrl: 'https://api.audience.peoplecloud.epsilon.com',
      baseUiUrl: 'https://audience.peoplecloud.epsilon.com',
      productBaseUrl: 'audiences',
      apiUrl: 'https://api.audience.peoplecloud.epsilon.com/cab/proxy/ams',
      cabApiUrl: {
        discovery: '',
        cab: 'https://api.audience.peoplecloud.epsilon.com',
      },
      dataHubApiUrl: 'https://api.datahub.epsilon.com',
      foundationApiUrl: 'https://api.a-us1.peoplecloud.epsilon.com',
      useMockData: false,
      // these features should come from backend which needs to be configurable
      features: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DEFAULT: {
          aggregations: true,
          scheduling: false,
          nestedAudiences: true,
          nestedDefinitions: false,
          audiences: true,
          notCondition: true,
          relativeDates: true,
          exportCampaignExtracts: true,
          showUpdateMetadata: false,
          showPCBCNavTree: false,
          attributeSearch: true,
          audienceBuilderEnumSelection: true,
          audienceBuilderWizardFlow: false,
          ownedChannelMapping: false,
          cabAttributeActions: true,
          paidConnectionsInfo: false,
          audienceQueryCaseInsensitive: true,
          activationSchedules: false,
          rolePermissionService: false,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_CUSTOMER: {
          showPCBCNavTree: false
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPCL: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          scheduling: false,
          showPCBCNavTree: true,
          exportCampaignExtracts: false,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DCDP: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          scheduling: false,
          showPCBCNavTree: false,
          exportCampaignExtracts: false,
          paidConnectionsInfo: false,
          assertListing: true,
          savedSearch: false,
          tagsFilter: true,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        EPC_PROSPECT: {
          audienceBuilderWizardFlow: true,
          ownedChannelMapping: true,
          cabAttributeActions: false,
          scheduling: false,
          showPCBCNavTree: true,
          exportCampaignExtracts: false,
          audiences: false
        }
      }
    }
  ]
]);
