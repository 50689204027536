import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { selectContext, selectContextId } from '../context/context.reducer';
import {
  DEFINITION_TXT,
  isDefined
} from '../utils/utils';
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  Observable
} from 'rxjs';
import {
  filter,
  take,
  map,
  distinctUntilChanged
} from 'rxjs/operators';
import { Actions } from '@ngrx/effects';
import {
  convertAudienceBuilderToDefinition,
  convertAudienceDefinitionToBuilder,
  errorIncludes,
  getParsedApiErrorMessage,
  nameValidators,
} from '../audience/audience.utils';
import { SaveAudienceDefinition } from '../audience/audience.actions';
import { fetchIfUnfetched, fetchOutcome } from '../utils/fetch-state';
import { Audience, AudienceDefinition } from '../audience/audience.models';
import { find as _find, reject, values, cloneDeep } from 'lodash-es';
import { AudienceService } from '../audience/audience.service';
import { CountsService } from '../counts/counts.service';
import { selectActiveDataUniverseId, selectDataUniverses, selectDedupeTypes } from '../data-universe/data-universe.reducer';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SetActiveDataUniverse } from '../data-universe/data-universe.actions';
import { DataType } from '../enums/data-types';
import { DataUniverse } from '../data-universe/data-universe.models';
import { DataTypeOperator } from '../models/data-type-operator';
import { UrlService } from '../services/url.service';
import { get as _get, keyBy, find, first } from 'lodash-es';
import { DedupeType } from '../models/dedupe-type';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { BuilderAudience, ERROR_DEDUPE_TYPE, ERROR_GENERIC, ERROR_NAME, ERROR_NO_ATTRIBUTE, ERROR_NO_ATTRIBUTE_VALUE } from '../audience-builder/audience-builder.models';
import { FetchDataTypeOperators, LoadDependentDefinitions, SetPrebuiltAudience, ToggleFullSegmentPath } from '../audience-builder/audience-builder.actions';
import { selectDataTypeOperators, selectPrebuiltAudience } from '../audience-builder/audience-builder.reducer';
import * as actions from '../audience-builder/audience-builder.actions';
import * as audienceActions from '../audience/audience.actions';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  FEATURE_ASSET_LISTING,
  FEATURE_AUDIENCE_BUILDER_WIZARD_FLOW,
  FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE,
  FEATURE_TAGS_FILTERING
} from '../utils/feature-utils';
import { selectAudienceDefinitions } from '../audience/audience.reducer';
import { DatePipe } from '@angular/common';
import { SetUnsavedChangesState } from '../hasUnsavedChanges/hasUnsavedChanges.actions';
import { DiscardTypes } from '../enums/discard-types';
import { FeatureService } from '../utils/feature-service';
import { UtilsService } from '../utils/utilservice';
import { AssetService, AssetType } from '@epsilon-cdp/pcm-common-lib';

@UntilDestroy()
@Component({
  selector: 'lib-audience-builder-base',
  templateUrl: './audience-builder-base.component.html',
  styleUrls: ['./audience-builder-base.component.sass']
})
export class AudienceBuilderBaseComponent implements OnInit, OnDestroy {
  audienceDefinitionId;
  isAudienceWizardFlow: boolean;
  @ViewChild('successNotification', { static: true })
  public successNotification;
  @ViewChild('errorNotification', { static: true }) public errorNotification;
  @ViewChild('showNestedDefinitionError', { static: true }) public showNestedDefinitionError;
  @ViewChild('showNestingLimitError', { static: true }) public showNestingLimitError;
  @ViewChild('saveDefinitionOnVersionChange')
  public saveDefinitionOnVersionChange;
  @ViewChild('saveAsNewDefinitionOnVersionChange',  { static: true }) public saveAsNewDefinitionOnVersionChange;
  @ViewChild('nestedLimitModal')
  public nestedLimitModal;
  @ViewChild('dependentDefinitionModal')
  public dependentDefinitionModal;
  audienceForm: UntypedFormGroup;
  @ViewChild('definitionTab')
  public definitionTab;
  audiences: AudienceDefinition[];
  prebuiltAudience: BuilderAudience;
  contextId: string;
  activeDataUniverseId: string;
  nonActiveDefinitions: AudienceDefinition[] = [];
  errorOnLoadState$ = new BehaviorSubject<boolean>(false);
  errorMessage = '';
  error$ = new BehaviorSubject<string>('');
  activeDedupeType: DedupeType;

  isSetUpComplete: boolean;
  version = 0;
  orderedWizardIndex = 0;
  dataUniverseId: string;
  audienceDefinitionRouter: string;
  audienceBuilderRouter: string;
  dedupeIdentityType: string;
  audienceDefinitionPreviousValuesFromServer;
  audiences$ = this.store.select(selectAudienceDefinitions);
  dataTypeOperators$: Observable<DataTypeOperator[]> = this.store.select(selectDataTypeOperators);
  currentAudienceDefinitionId: string;
  isEdit: boolean;
  createdDetails: string;
  modifiedDetails: string;
  dependentDefinitions: AudienceDefinition[];
  currentDependentDefinition: AudienceDefinition;
  maxNestedDefsReachedError: boolean;
  maxNestedDefsReachedErrorMsg: string;
  maxNestingLimitDepthReachedErrorMsg: string;
  selectDedupeTypes: DedupeType[];
  productType: string;
  dataUniverseName = '';
  allowTagsEnabled = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public actions$: Actions,
    public urlService: UrlService,
    public store: Store<AppState>,
    public builderService: AudienceBuilderService,
    public audienceService: AudienceService,
    public countsService: CountsService,
    public datePipe: DatePipe,
    public featureService: FeatureService,
    private utilsService: UtilsService,
    private assetService: AssetService
  ) {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
        this.isAudienceWizardFlow = this.featureService.isFeatureEnabled(FEATURE_AUDIENCE_BUILDER_WIZARD_FLOW);
      });

    observableCombineLatest([
      this.builderService.activeDataUniverseId$,
      store.select(selectContextId).pipe(filter(isDefined)),
      store.select(selectDataUniverses).pipe(filter(isDefined))
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dataUniverseId, contextId, dataUniverse]) => {
        this.contextId = contextId;
        this.activeDataUniverseId = dataUniverseId;
        this.dataUniverseName = dataUniverse.find((item) => {
          return item.id === this.activeDataUniverseId;
        })?.displayName;
        fetchIfUnfetched(
          this.store,
          new FetchDataTypeOperators(this.contextId, this.activeDataUniverseId),
          this
        );
      });

    observableCombineLatest([
      this.audiences$.pipe(map(values)),
      this.store.select(selectPrebuiltAudience).pipe(filter(isDefined)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([audiences, prebuiltAudience]: [
          AudienceDefinition[],
          BuilderAudience
        ]) => {
          this.audiences = audiences;
          this.nonActiveDefinitions = reject(audiences, {
            id: prebuiltAudience?.id,
          });
          this.prebuiltAudience = prebuiltAudience;
          const { idCount, countUpdatedOn, errorMessage, countStatus } = this.prebuiltAudience;

          if (this.prebuiltAudience) {
            this.builderService.checkForAttribute();
          }

          if (Number.isInteger(idCount)) {
            const count = idCount.toString();
            this.countsService.builderCount$.next({
              displayCount: count,
              countUpdatedOn,
              errorMessage,
              status: countStatus
            });
          }
        }
      );
    this.audienceForm = new UntypedFormGroup({
      displayName: new UntypedFormControl('', [
        ...nameValidators,
        this.uniqueNameValidator(),
      ]),
      description:new UntypedFormControl(),
      includeConditions: new UntypedFormControl(
        []
      ),
      excludeConditions: new UntypedFormControl(
        []
      ),
      dedupeType: new UntypedFormControl(''),
      identityType: new UntypedFormControl('', [Validators.required]),
      channelType: new UntypedFormControl('PAID'),
      profileType: new UntypedFormControl(''),
      dedupeAllProfiles: new UntypedFormControl(false),
      audienceQueryCaseInsensitive: new UntypedFormControl(true),
      alternateKey : new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.builderService.initAudienceData();
    this.audienceDefinitionId =
      this.route.snapshot.paramMap.get('definitionId');
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.audienceDefinitionRouter = '/'+this.utilsService.getProductBaseUrl(this.router, this.route)+'/'+ + this.contextId + '/definition';
    const audienceDefinitionId =
      this.route.snapshot.paramMap.get('definitionId');
    if (audienceDefinitionId) {
      this.isEdit = true;
    } else {
      this.audienceForm.valueChanges.subscribe(() => {
        this.builderService.audienceBuilderUpdatedManually$.next(true);
        this.builderService.audienceBuilderCriteriaManually$.next(true);
      });
    }

    observableCombineLatest([
      this.route.params.pipe(distinctUntilChanged()),
      this.store.select(selectDataUniverses),
      this.store.select(selectActiveDataUniverseId),
      this.store.select(selectContextId).pipe()
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([params, dataUniverses, activeDataUniverseId]: [
          Params,
          DataUniverse[],
          string,
          any
        ]) => {
          const dataUniverseId = params['dataUniverseId'];
          if (activeDataUniverseId === 'all') {
            const dataUniverse = _find(dataUniverses, { id: dataUniverseId });
            if (!dataUniverse) {
              this.errorOnLoadState$.next(true);
              console.error(
                'an error because there was no matching data universe'
              );
              return;
            }
            this.store.dispatch(new SetActiveDataUniverse(dataUniverse));
          } else if (audienceDefinitionId && this.contextId) {
            this.route.data.subscribe((response: any) => {
              const { audienceDefinition, canAddNestedDefinitions, nodes, isUsed } = _get(response.audienceData, 'entity');
                if (!canAddNestedDefinitions) {
                  setTimeout(() => {
                    const elements: Element[] = Array.from(document.querySelectorAll('.Core-Accordion-paneHeader'));
                    elements.forEach((el: HTMLButtonElement, index) => {
                      if (el.innerText.includes('Audience Definitions') || el.innerText.includes('Definitions')) {
                        const defDIv = (document.querySelectorAll('.Core-Accordion-paneHeader')[index] as HTMLButtonElement);
                        const defText = (document.querySelectorAll('.Core-Accordion-paneHeaderText')[index] as HTMLButtonElement);
                        const chevronDiv = (document.querySelectorAll('.Core-Accordion-paneHeaderIcon')[index] as HTMLElement);
                        if (!defDIv.innerHTML.includes('Core-Icon--help')) {
                          defDIv.insertAdjacentHTML('beforeend', '<div style="pointer-events: all;z-index: 9999; position: relative;" class="Core-Accordion-paneHeaderIcon ng-tns-c50-6"><div style="align-items: center; vertical-align: middle" class="Core-Accordion-paneHeaderIconWrapper ng-tns-c50-6"><i style="transform: scale(1.2);" class="Core-Icon Core-Icon--help"></i><span class="hoverme"> Why definitions are disabled ? Current definition is used in other definitions. You can’t nest other definitions in the current one. View “where this being used” for more details.</span></div></div>');
                          defDIv.disabled = true;
                          defText.style.color = '#666666';
                          defDIv.style.pointerEvents = 'none';
                          chevronDiv.style.display = 'none';
                        }
                      }
                    });
                  }, 1000);
                }
                let builderAudience = null;
                if(audienceDefinition) {
                  builderAudience = convertAudienceDefinitionToBuilder(
                    new AudienceDefinition(audienceDefinition),
                    true
                  );
                  builderAudience = {...builderAudience, isUsed};
                } else {
                  builderAudience = convertAudienceDefinitionToBuilder(
                    new Audience(audienceDefinition),
                    true
                  );
                }

                this.store.select(selectDedupeTypes(dataUniverseId)).subscribe((dedupeTypes) => {
                  this.selectDedupeTypes = dedupeTypes;
                });

                let communicationChannels = null;
                if (this.productType === 'DCDP') {
                  communicationChannels = this.populateIdentityType(audienceDefinition);
                }
                this.audienceForm = new UntypedFormGroup({
                  displayName: new UntypedFormControl(audienceDefinition.displayName, [
                    ...nameValidators,
                    this.uniqueNameValidator(),
                  ]),
                  description:new UntypedFormControl(audienceDefinition.description),
                  includeConditions: new UntypedFormControl(audienceDefinition?.query?.includeConditions),
                  excludeConditions: new UntypedFormControl(audienceDefinition?.query?.excludeConditions),
                  dedupeType: new UntypedFormControl(audienceDefinition?.dedupeIdentityType),
                  identityType: new UntypedFormControl(audienceDefinition?.dedupeIdentityType),
                  channelType: new UntypedFormControl({value: communicationChannels?.channelType, disabled: true}),
                  profileType: new UntypedFormControl({value: communicationChannels?.profileType, disabled: true}),
                  audienceQueryCaseInsensitive: new UntypedFormControl(!audienceDefinition.query?.queryFlags?.caseInsensitive),
                  alternateKey: new UntypedFormControl(audienceDefinition?.audienceAttributes?.alternateKeyType || '')
                });
                if (this.productType === 'DCDP') {
                  this.audienceForm.addControl('dedupeAllProfiles', new UntypedFormControl({value: (communicationChannels.profileType === 'allprofiles' && communicationChannels.allProfiles) ? false : true, disabled: true}));
                  this.audienceForm.get('identityType').setValue(audienceDefinition?.dedupeIdentityType);
                  this.audienceForm.get('identityType').disable();
                  this.audienceForm.get('alternateKey').disable();
                }

                this.audienceForm.valueChanges.subscribe(() => {
                  this.builderService.audienceBuilderUpdatedManually$.next(true);
                });

                this.builderService.rules = [audienceDefinition?.query?.includeConditions, audienceDefinition?.query?.excludeConditions];
                this.audienceDefinitionPreviousValuesFromServer = cloneDeep(builderAudience);
                this.store.dispatch(new actions.LoadAttributeDetails(keyBy(nodes, 'cabId')));
                this.store.dispatch(new actions.LoadPrebuiltAudience(builderAudience));
                this.store.dispatch(
                  new SetPrebuiltAudience(
                    builderAudience,
                    this.contextId,
                    DataType.AUDIENCE_DEFINITION
                  )
                );
                this.store.dispatch(new audienceActions.LoadAudienceForCreateList(audienceDefinition));
                // attempt to restart cron if any
                this.countsService.restartCron(builderAudience);
              }, (error) => {
                  if(error.status >= 500) {
                    this.router.navigate([
                      this.utilsService.getProductBaseUrl(this.router, this.route),
                        this.contextId,
                        DEFINITION_TXT,
                      ]);
                  }
            });
            this.builderService.fetchCabDependentDefinitions(this.contextId, audienceDefinitionId).subscribe((data) => {
              // this.dependentDefinitions = _get(data, 'results');
              this.store.dispatch(new LoadDependentDefinitions(_get(data, 'results')));
            });
          }
        }
      );

    this.errorOnLoadState$
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe(() => {
        this.errorNotification.show();
        this.audienceForm.disable();
      });

    this.error$.pipe(untilDestroyed(this)).subscribe((message) => {
      this.errorMessage = message;
      this.errorNotification.show();
    });
    this.builderService.audienceBuilderUpdatedManually$.next(false);
    this.builderService.audienceBuilderCriteriaManually$.next(false);
  }

  ngOnDestroy() {
    const prebuiltAudienceId = this.prebuiltAudience?.id;
    if (prebuiltAudienceId) {
      this.countsService.stopCron(prebuiltAudienceId);
    }
    this.countsService.resetBuilderCounts();
    this.builderService.audienceBuilderUpdatedManually$.next(false);
    this.builderService.audienceBuilderCriteriaManually$.next(false);
    this.store.dispatch(new SetPrebuiltAudience(null));
    this.store.dispatch(new ToggleFullSegmentPath(null));
    this.store.dispatch(new SetUnsavedChangesState(null));
  }

  populateIdentityType (audienceDefinition: AudienceDefinition): {channelType: string; profileType: string} {
    const communicationChannels = {
      channelType: 'PAID',
      profileType: null,
      allProfiles: false
    };
    if (audienceDefinition.audienceAttributes?.channelType === 'PAID') {
      return communicationChannels;
    } else {
      const selectedIdentityType = this.selectDedupeTypes.filter((selectedDedupetype) => {
        return selectedDedupetype.identityType === audienceDefinition.dedupeIdentityType;
      });
      communicationChannels.channelType = 'OWNED';
      if (selectedIdentityType.length > 0) {
        if (selectedIdentityType[0].productIdentityInfo?.goldenProfile) {
          communicationChannels.profileType = 'goldenprofile';
        } else {
          if (selectedIdentityType[0].productIdentityInfo?.profile) {
            communicationChannels.allProfiles = true;
          }
          communicationChannels.profileType = 'allprofiles';
        }
      }
      return communicationChannels;
    }
  }

  canSaveDefinition() {
    this.builderService.checkForAttributeValues();

    return this.isAudienceValid();
  }

  save(saveAsNewOnVersionUpdate: boolean) {
    if (this.canSaveDefinition()) {
      this.builderService.audience.audienceQueryCaseInsensitive = !this.audienceForm?.get('audienceQueryCaseInsensitive')?.value;
      const definition = convertAudienceBuilderToDefinition(
        this.builderService.audience,
        this.contextId,
        this.activeDataUniverseId
      );
      definition.displayName = this.audienceForm.get('displayName').value;
      definition.description = this.audienceForm.get('description').value;
      definition.dedupeIdentityType = this.audienceForm.get('dedupeType').value;
      if (this.productType === 'DCDP') {
        definition.audienceAttributes = {
          channelType: this.audienceForm.get('channelType').value,
          alternateKeyType:  definition.dedupeIdentityType === "AlternateKey" ? this.audienceForm.get('alternateKey').value : ''
        };
      }
      if(!this.featureService.isFeatureEnabled(FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE)) {
        delete definition.query?.queryFlags;
      }

      this.store.dispatch(new SaveAudienceDefinition(definition));
      this.actions$
        .pipe(fetchOutcome(SaveAudienceDefinition.type), take(1))
        .subscribe(
          ({ result }) => {
            this.builderService.audienceBuilderCriteriaManually$.next(false);
            const savedDefinition = result.audienceDefinition;
            this.setPrebuiltAudience(savedDefinition);
            this.store.dispatch(new SetUnsavedChangesState(null));
            this.isEdit = true;
            this.allowTagsEnabled = this.featureService.isFeatureEnabled(FEATURE_TAGS_FILTERING) && this.featureService.isFeatureEnabled(FEATURE_ASSET_LISTING) && this.productType ==='DCDP';
            const selectedAssetTags = this.audienceForm.get('assetTags')?.value?.length;
            if(savedDefinition.id && this.allowTagsEnabled && selectedAssetTags){
              this.associateTags(savedDefinition.id);
            }
            if (!definition.id && !saveAsNewOnVersionUpdate) {
              this.router.navigate([
                this.utilsService.getProductBaseUrl(this.router, this.route),
                this.contextId,
                DEFINITION_TXT,
              ],
                { state: {showDefinitionSaveSuccess: true, audienceDefinitionName: definition.displayName}});
            }
            else if (!definition.id && saveAsNewOnVersionUpdate) {
              this.router.navigate([
                this.utilsService.getProductBaseUrl(this.router, this.route),
                this.contextId,
                DEFINITION_TXT,
              ],
                { state: {showDefinitionSaveSuccess: true, audienceDefinitionName: definition.displayName}});
            }
            else if (definition.id) {
             this.successNotification.show();
            }

            this.definitionTab?.onSaveCallback();
            this.resetFormUpdateBoolean();
          },
          (error) => {
            this.store.dispatch(new SetUnsavedChangesState(null));
            if (errorIncludes(error.error, 'Operation failed, possibly because the entity was updated in the meanwhile by another user')) {
              this.openSaveDefinitionVersionChangeModal();
            } else if (errorIncludes(error.error, 'Maximum number of nested Audience Definitions')) {
              for (const errorObj of error.error.errorDetails) {
                this.errorMessage = errorObj.errorMessage;
              }
              this.showNestedDefinitionError.show();
            }
            else {
              this.maxNestedDefsReachedError = errorIncludes(error.error, 'Please reduce to 10 and try again');
              const maxNestDepthLimitReached = errorIncludes(error.error, 'already has 5 levels of nesting; it cannot be nested');
              if(this.maxNestedDefsReachedError|| maxNestDepthLimitReached) {
                for (const errorObj of error.error.errorDetails) {
                  if(errorObj.errorMessage.includes('Please reduce to 10 and try again'))
                  {
                    this.maxNestedDefsReachedError = true;
                    this.showNestedDefinitionError.show();
                    this.maxNestedDefsReachedErrorMsg = errorObj.errorMessage;
                  }
                  else{
                    this.maxNestedDefsReachedError = false;
                    this.showNestingLimitError.show();
                    this.maxNestingLimitDepthReachedErrorMsg = errorObj.errorMessage;
                  }
                }
              }
              else{
                this.parseApiErrorMessage(error);
              }
              // TODO: utilize backend error messages
            }
          }
        );
    } else {
      this.handleInvalidDefinitionError();
    }

  }
  associateTags(definitionId:string) {
    const selectedAssetTags = this.audienceForm.get('assetTags')?.value;
    const assetTags$ = definitionId ? selectedAssetTags?.map(value => ({
      namespace: '',
      tag: value
    })) : [];

    const tagAssociationRequest = {
      assetId: definitionId,
      tags: assetTags$,
      type: AssetType.AUDIENCE_DEFINITION
    };

    this.assetService.associateTags(tagAssociationRequest).pipe(untilDestroyed(this)).subscribe();
  }

  setPrebuiltAudience(definition) {
    const audience = convertAudienceDefinitionToBuilder(definition, true);

    this.store.dispatch(
      new SetPrebuiltAudience(
        audience,
        this.contextId,
        DataType.AUDIENCE_DEFINITION,
        true
      )
    );
  }

  isNameValid(): boolean {
    return this.audienceForm.get('displayName').valid;
  }

  isDedupeTypeValid(): boolean {
    return this.audienceForm.get('dedupeType').valid;
  }

  handleInvalidDefinitionError() {
    this.audienceForm.get('displayName').updateValueAndValidity();
    const errorMsg = !this.builderService.definitionHasAttribute$.value
      ? ERROR_NO_ATTRIBUTE
      : !this.builderService.allExpressionsHaveValues$.value
        ? ERROR_NO_ATTRIBUTE_VALUE
        : this.isNameValid()
          ? this.isDedupeTypeValid()
            ? ERROR_DEDUPE_TYPE
            : ERROR_GENERIC
          : ERROR_NAME;

    this.error$.next(errorMsg);
  }

  cancel() {
    this.builderService.audienceBuilderUpdatedManually$.next(false);
    this.builderService.audienceEditSavedSuccess$.next(false);
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      DEFINITION_TXT,
    ]);
    this.store.dispatch(new SetPrebuiltAudience(null));
  }

  openSaveDefinitionVersionChangeModal() {
    this.countsService.stopCron();
    this.countsService.resetBuilderCounts();
    this.saveDefinitionOnVersionChange.show();
  }

  saveAsNewAudienceDefinition() {
    this.builderService.audience.displayName = '';
    this.currentAudienceDefinitionId = this.builderService.audience.id;
    this.builderService.audience.id = '';
    this.builderService.audience.version = 0;
    this.countsService.builderCount$.next({ displayCount: 'N/A', countUpdatedOn: null, errorMessage: null, status: null });
    this.saveAsNewDefinitionOnVersionChange.show();
  }

  isAudienceValid() {
    return (
      this.builderService.definitionHasAttribute$.value &&
      this.builderService.allExpressionsHaveValues$.value &&
      this.builderService.audience
    );
  }

  isSaveButtonDisabled() {
    return !this.builderService.definitionHasAttribute$.value;
  }

  saveButtonErrorTooltip() {
    const messages = [];
    const controls = this.audienceForm.controls;
    if (controls['displayName']?.status === 'INVALID') {
      messages.push(ERROR_NAME);
    }
    if (!this.builderService.definitionHasAttribute$.value) {
      messages.push(ERROR_NO_ATTRIBUTE);
    }
    if (controls['dedupeTypes']?.status === 'INVALID') {
      messages.push(ERROR_DEDUPE_TYPE);
    }
    return messages;
  }

  private uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const nameTaken = this.nonActiveDefinitions
        .map((o) => o?.displayName?.toLowerCase())
        .includes(control.value?.trim()?.toLowerCase());
      return nameTaken ? { nameTaken: true } : null;
    };
  }

  parseApiErrorMessage(error) {
    // TODO: utilize backend error messages
    let errorMsg = getParsedApiErrorMessage(error);
    // TODO: temporary solution until we can properly parse errorDetails
    if (errorIncludes(error, 'Dedupe type of Nested')) {
      errorMsg =
        'Cannot use an attribute with dedupe type that does not match the definitions dedupe type.';
    } else if (
      errorIncludes(error, 'number of nested Audience Definitions') ||
      errorIncludes(error?.error, 'Number of definitions exceeded')
    ) {
      errorMsg = 'Maximum number of nested definitions exceeded.';
    } else if (
      errorIncludes(
        error?.error,
        'Either includeConditions or excludeConditions is required.'
      )
    ) {
      errorMsg = ERROR_NO_ATTRIBUTE;
    } else if (errorIncludes(error, 'Null or Blank value')) {
      errorMsg = ERROR_NO_ATTRIBUTE_VALUE;
    } else if(errorIncludes(error?.error, 'already exists in')) {
      errorMsg = error?.error.errorDetails[0].errorMessage;
    }
    this.error$.next(errorMsg);
  }

  private getAudienceDefinition(id: string, contextId: string) {
    return this.audienceService
      .fetchAudienceDefinition(id, contextId, true)
      .toPromise();
  }

  showNestedLimitModal()
  {
    this.nestedLimitModal.show();
  }


  // saveCurrentDefinitionChanges() {
  //   this.save(false);
  //   this.dependentDefinitionModal.hide();
  //   this.routeTONewDefinition();
  // }

  // discardAudienceDefinitionChangesAndRoute() {
  //   this.discardAudienceDefinitionChanges(false);
  //   this.dependentDefinitionModal.hide();
  //   this.router.navigateByUrl(this.router.url.replace(this.prebuiltAudience.id, this.currentDependentDefinition.id));
  //   this.routeTONewDefinition();
  // }

  // private routeTONewDefinition() {
  //   this.router.navigate([
  //     getProductBaseUrl(this.router, this.route),
  //     this.contextId,
  //     this.activeDataUniverseId,
  //     BUILDER_TXT,
  //     'edit',
  //     this.currentDependentDefinition.id,
  //   ]).then(() => {
  //     window.location.reload();
  //   });;
  // }

  onDedupeTypeSelectChange(dedupeType) {
    this.builderService.audience.dedupeIdentityType = dedupeType;
  }

  onDiscardChanges($event) {
    if ($event === DiscardTypes.DISCARD_AND_CONTINUE) {
      const includeConditions = cloneDeep(this.audienceDefinitionPreviousValuesFromServer?.includeConditions);
      const excludeConditions = cloneDeep(this.audienceDefinitionPreviousValuesFromServer?.excludeConditions);
      this.builderService.audience.includeConditions = includeConditions;
      this.builderService.audience.excludeConditions = excludeConditions;
      this.builderService.audience.audienceQueryCaseInsensitive = this.audienceDefinitionPreviousValuesFromServer?.audienceQueryCaseInsensitive;
      this.builderService.rules = [includeConditions, excludeConditions];
    }
  }

  setDefaultValue(dedupeTypes: DedupeType[]) {
    const defaultValue =
      this.builderService.audience.dedupeIdentityType ||
      find(dedupeTypes, { primaryIdentityType: true })?.identityType ||
      first(dedupeTypes)?.identityType;
    this.audienceForm.get('dedupeType').setValue(defaultValue);
  }

  private resetFormUpdateBoolean() {
    this.audienceForm.markAsPristine();
    this.audienceForm.markAsUntouched();
    this.builderService.audienceBuilderUpdatedManually$.next(false);
    this.builderService.audienceBuilderCriteriaManually$.next(false);
  }
}
