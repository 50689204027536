import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AudienceAIChatRequest, ChatConversation, ChatMessage, AudienceAIChatResponse, UserType, AudienceAIResponseType, AudiencePreviewInfo, CountResponse } from '../audience-ai.model';
import { AudienceAIChatService } from '../audience-ai-chat.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CabConstants } from "../../cab.constants";
import { AudienceDefinitionGenerateAIRating } from '../../audience-builder/audience-builder.models';
import { AudienceBuilderService } from '../../audience-builder/audience-builder.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConfirmationPopupComponent } from "../../shared/components/confirmation-popup/confirmation-popup.component";
import { NotificationComponent } from "@epsilon/core-ui";
import { AudienceService } from '../../audience/audience.service';

@UntilDestroy()
@Component({
  selector: "lib-audience-ai-dailog",
  templateUrl: "./audience-ai-dailog.component.html",
  styleUrls: ["./audience-ai-dailog.component.sass"],
})
export class AudienceAIDailogComponent implements OnInit {

  isQueryRunning = false;
  human = UserType.HUMAN;
  assistant = UserType.ASSISTANT;
  audienceAIChatRequest: AudienceAIChatRequest;
  chatConversations: ChatConversation;
  audienceAIFormGroup: UntypedFormGroup;
  contextId: string;
  confirmationTitle = 'Do you want to clear the conversation?'
  customMessage = 'Are you sure you want to restart the session, you\'ll lose all the progress of the current session.';
  confirmText = 'Yes, Clear';
  cancelText = 'Go Back';
  isUserInputValid = false;
  savedAudienceDefinitionId: string;
  dataUniverseId: string;

  @ViewChild('chatMessages') private chatContainer!: ElementRef | undefined;
  @ViewChild('previewContent') private previewContainer!: ElementRef | undefined;
  @ViewChild(ConfirmationPopupComponent) private confirmationPopup: ConfirmationPopupComponent;
  @ViewChild('toastSuccess', { static: true }) public toastSuccess: NotificationComponent;
  @ViewChild('toastError', { static: true }) public toastError: NotificationComponent;

  constructor(public audienceAIChatService: AudienceAIChatService,
    public builderService: AudienceBuilderService,
    private route: ActivatedRoute, private audienceService: AudienceService, private router: Router) {}

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    this.initialize();
    this.initiateChat();
  }

  private initialize(): void {
    this.audienceAIChatRequest = new AudienceAIChatRequest();
    this.audienceAIChatRequest.data_universe_id = this.dataUniverseId;
    this.chatConversations = new ChatConversation();
    this.audienceAIFormGroup = this.buildAudienceAIFormGroup();
  }

  private buildAudienceAIFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      chatInput: new UntypedFormControl(""),
      previewData: this.initPreviewDataFormGroup(),
      commentMessage: new UntypedFormControl("")
    });
  }

  private initPreviewDataFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      audience_name: new UntypedFormControl(""),
      description: new UntypedFormControl(""),
      channel: new UntypedFormControl(""),
      audience_type: new UntypedFormControl(""),
      dedupe: new UntypedFormControl(""),
      input_query: new UntypedFormControl(""),
      audience_query: new UntypedFormControl("")
    });
  }

  public initiateChat(): void {
    const chatMessage = new ChatMessage({role: UserType.HUMAN, content: CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE});
    this.buildChatConversations(chatMessage);
    this.audienceAIChatRequest.input_message = CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE;
    this.performChat(this.audienceAIChatRequest);
    this.updateChatMessages(CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE, UserType.HUMAN);
  }

  public submitQuery(): void {
    if (!this.audienceAIFormGroup.get("chatInput").value || this.audienceAIFormGroup.get("chatInput").value.trim().length === 0
      || this.audienceAIFormGroup.get("chatInput").errors || this.isQueryRunning) {
      return;
    }
    const userMessage = this.audienceAIFormGroup.get("chatInput").value;
    const chatMessage = new ChatMessage({role: UserType.HUMAN, content: userMessage});
    this.buildChatConversations(chatMessage);
    this.audienceAIChatRequest.input_message = userMessage;
    this.performChat(this.audienceAIChatRequest);
    this.updateChatMessages(userMessage, UserType.HUMAN);
  }

  private buildChatConversations(chatMessage: ChatMessage) {
    this.chatConversations.chat_history.push(chatMessage);
    this.audienceAIFormGroup.get("chatInput").reset();
    this.isUserInputValid = false;
  }

  private scrollToBottom(container: ElementRef): void {
    if (this.chatContainer && this.previewContainer) {
      setTimeout(() => {
          container.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
      }, 0);
    }
  }

  private updateChatMessages(message: string, userType: UserType): void {
    const chatMessage = new ChatMessage({role: userType, content: message});
    this.audienceAIChatRequest.chat_history.push(chatMessage);
    this.scrollToBottom(this.chatContainer);
  }

  public clearChat(): void {
    this.audienceAIChatRequest = new AudienceAIChatRequest();
    this.audienceAIChatRequest.data_universe_id = this.dataUniverseId;
    this.chatConversations = new ChatConversation();
    this.audienceAIFormGroup.reset();
    this.audienceAIFormGroup.setControl("previewData", new UntypedFormGroup({}));
    this.isQueryRunning = false;
    this.initiateChat();
  }

  public selectOption(option: string): void {
    this.audienceAIFormGroup.get("chatInput").patchValue(option);
    this.submitQuery();
  }

  private performChat(audienceAIChatRequest: AudienceAIChatRequest) {
    this.isQueryRunning = true;
    this.audienceAIChatService
      .performChat(this.contextId, audienceAIChatRequest)
      .subscribe({
        next: async (response: AudienceAIChatResponse) => {
          if (response.response_type === AudienceAIResponseType.OK) {
            if (response.context_data.audience_count_job_id) {
              try {
                const result: CountResponse = await this.audienceAIChatService.pollJobStatus(response.context_data.audience_count_job_id, this.contextId).toPromise();
                if (result.status === "SUCCESS") {
                  const count = result.result.count;
                  response.chat_message = `Ok, I was able to run count. There are <b>${count}</b> profiles matching your criteria`;
                  const contentMessage = this.updateChatContent(response.chat_message, response.context_data.audience_definition_id);
                  const chatMessageConversation = new ChatMessage({ role: UserType.ASSISTANT, content: contentMessage, options: response.options });
                  this.buildChatConversations(chatMessageConversation);
                  const chatMessage = new ChatMessage({ role: this.assistant, content: response.chat_message });
                  this.audienceAIChatRequest.chat_history.push(chatMessage);
                } else if (result.status === 'FAILURE') {
                  response.chat_message = 'Failed to fetch the Count';
                  response.options.push('Try Again');
                  const contentMessage = this.updateChatContent(response.chat_message, response.context_data.audience_definition_id);
                  const chatMessageConversation = new ChatMessage({ role: UserType.ASSISTANT, content: contentMessage, options: response.options });
                  this.buildChatConversations(chatMessageConversation);
                  const chatMessage = new ChatMessage({ role: this.assistant, content: response.llm_output });
                  this.audienceAIChatRequest.chat_history.push(chatMessage);
                }
              } catch (error) {
                console.error('Error fetching job status:', error);
                response.options.push('Try Again');
                response.chat_message = 'Failed to fetch the Count';
                const contentMessage = this.updateChatContent(response.chat_message, response.context_data.audience_definition_id);
                const chatMessageConversation = new ChatMessage({ role: UserType.ASSISTANT, content: contentMessage, options: response.options });
                this.buildChatConversations(chatMessageConversation);
                const chatMessage = new ChatMessage({ role: this.assistant, content: response.llm_output });
                this.audienceAIChatRequest.chat_history.push(chatMessage);
              }
            } else {
              const contentMessage = this.updateChatContent(response.chat_message, response.context_data.audience_definition_id);
              const chatMessageConversation = new ChatMessage({ role: UserType.ASSISTANT, content: contentMessage, options: response.options });
              this.buildChatConversations(chatMessageConversation);
              const chatMessage = new ChatMessage({ role: this.assistant, content: response.llm_output });
              this.audienceAIChatRequest.chat_history.push(chatMessage);
            }
          } else if (response.response_type === AudienceAIResponseType.ERROR) {
            const chatMessageConversation = new ChatMessage({ role: UserType.ASSISTANT, content: response.chat_message, options: response.options });
            this.buildChatConversations(chatMessageConversation);
      
            if (response.chat_message.includes('Apologies! An unexpected error occurred')) {
              this.audienceAIChatRequest.chat_history.pop();
            } else {
              const chatMessage = new ChatMessage({ role: this.assistant, content: response.llm_output });
              this.audienceAIChatRequest.chat_history.push(chatMessage);
            }
          }
          this.scrollToBottom(this.chatContainer);
          const previewDataFormGroup = this.buildPreviewDataFormGroup(response.context_data.audience_preview);
          this.scrollToBottom(this.previewContainer);
          this.audienceAIFormGroup.setControl("previewData", previewDataFormGroup);
          this.isQueryRunning = false;
          this.updatePreviewData(response.context_data.audience_preview);
        },
        error: () => {
          this.audienceAIChatRequest.chat_history.pop();
          if (this.audienceAIChatRequest.chat_history.length < 2) {
            const errorHandlingMessage = "Apologies, Server error occurred! Can not initiate Audience AI";
            const chatMessage = new ChatMessage({role: UserType.ASSISTANT, content: errorHandlingMessage});
            this.buildChatConversations(chatMessage);
          } else {
            const prevChatConversation = this.chatConversations.chat_history[this.chatConversations.chat_history.length - 2];
            const errorHandlingMessage = `Apologies, Server Error Occurred! Let's Try Again \n\n${prevChatConversation.content}`;
            const chatMessage = new ChatMessage({
              role: UserType.ASSISTANT,
              content: errorHandlingMessage, 
              options: prevChatConversation.options, 
              genAIRequestId: prevChatConversation.genAIRequestId
            });
            this.buildChatConversations(chatMessage);
          }
          this.scrollToBottom(this.chatContainer);
          this.scrollToBottom(this.previewContainer);
          this.isQueryRunning = false;
        }
      });
  }

  private updateChatContent(message: string, audienceId: string) {
    let content = message;
    if (content.toLowerCase().includes("saved audience")) {
      const baseUrl = window.location.origin + '/app';
      const lastSlashIndex = this.router.url.lastIndexOf('/');
      const domainUrl = this.router.url.substring(0, lastSlashIndex);
      const audienceBuilderUrl = `${baseUrl}${domainUrl}/builder/edit/${audienceId}`;
      const link = `<a href="${audienceBuilderUrl}" target="_blank">Edit audience</a>`;
      content = content.replace("SAVED_AUDIENCE_CH", link);
    }
    return content;
  }

  private buildPreviewDataFormGroup(data: any): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    for (const key in data) {
      if (data[key]) {
        formGroup.addControl(key, new UntypedFormControl({ value: data[key], disabled: false }));
      }
    }
    return formGroup;
  }

  private updatePreviewData(data: Partial<AudiencePreviewInfo>): void { // NOSONAR
    const previewDataGroup = this.audienceAIFormGroup.get('previewData') as UntypedFormGroup;
    previewDataGroup.patchValue(data);
  }

  public getTransformedText(chatMessage: string): string {
    return chatMessage?.replace(/\n/g, "<br/>") ?? "";
  }

  public getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  public selectFeedback(userRating: number, chatIndex: number): void {
    this.chatConversations.chat_history[chatIndex].feedbackRating = userRating;
    this.chatConversations.chat_history[chatIndex].isFeebackCommentGiven = false;
    if ((this.chatConversations.chat_history.length - 1) === chatIndex) {
      this.scrollToBottom(this.chatContainer);
    }
  }

  public submitFeedback(chatIndex: number): void {
    const userRating = this.chatConversations.chat_history[chatIndex].feedbackRating;
    const generateFromTextRequest: AudienceDefinitionGenerateAIRating = {
      cabContextId: this.route.snapshot.paramMap.get('contextId'),
      genAIRequestId: this.chatConversations.chat_history[chatIndex].genAIRequestId,
      userRating,
      userComments: this.audienceAIFormGroup.get("commentMessage").value
    };
    this.builderService.generateAudienceDefinitionGenAIQueryRating(generateFromTextRequest)
      .subscribe(() => {
        this.audienceAIFormGroup.get("commentMessage").reset();
        this.showToast(this.toastSuccess);
        this.chatConversations.chat_history[chatIndex].isFeebackCommentGiven = true;
      }, () => {
        this.showToast(this.toastError);
      });
  }

  openConfirmationPopup(): void {
    this.confirmationPopup.launchAlertModal();
  }

  handleConfirm(): void {
    this.clearChat();
  }

  handleCancel(): void {
    return;
  }

  onChatInputChange(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    const value = textarea.value;
    this.isUserInputValid = (value && value.trim().length > 0) ? true : false;
    this.adjustInputHeight(textarea);
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  public isFeedbackMenuShown(genAIRequestId: string): boolean {
    return genAIRequestId ? true : false;
  }

  public isFeedbackSelected(index: number, rating: number): boolean {
    return this.chatConversations.chat_history[index].feedbackRating === rating ? true : false;
  }

  public isCommentAreaShown(index: number): boolean {
    return this.chatConversations.chat_history[index].feedbackRating !== 0
      && this.chatConversations.chat_history[index].isFeebackCommentGiven === false;
  }

  public adjustInputHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'fit-content';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        return;
      } else {
        event.preventDefault();
        this.submitQuery();
      }
      const textarea = event.target as HTMLTextAreaElement;
      textarea.style.height = 'fit-content';
    }
  }

}
