<coreui-menu verticalPosition="bottom" menuButtonStyle="link" [autoHide]="true" icon="settings" class="viewClass"
    data-dcdp="details-settings">
    <coreui-menu-section>
        <div class="toggle-container">
            <lib-segment-path-toggle toggleLabel="Show full path"></lib-segment-path-toggle>
        </div>
        <div class="toggle-container">
            <span>Case sensitive</span>
            <div>
                <coreui-toggle [checked]="caseInsensitiveDisabled ? !caseInsensitive : caseInsensitive"
                    [disabled]="caseInsensitiveDisabled" (valueChange)="toggleCaseInsensitive($event)"></coreui-toggle>
            </div>
        </div>
    </coreui-menu-section>
</coreui-menu>
